import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { FeaturesComponent } from './features/features.component';
import { StoryComponent } from './story/story.component';
import { TeamComponent } from './team/team.component';
import { BooksComponent } from './books/books.component';
import { ContactComponent } from './contact/contact.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

const routes: Routes = [
  { path: 'home', redirectTo: ''},
  { path: 'services', component: ServicesComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'story', component: StoryComponent },
  { path: 'team', component: TeamComponent},
  { path: 'books', component: BooksComponent},
  { path: 'contact-us', component: ContactUsComponent},
  { path: '', component: HomeComponent, pathMatch: 'full'},
  { path: '**', component: ErrorComponent }
];

@NgModule({
  declarations: [],  
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
