import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      $('.flexslider').flexslider({
        animation: "fade",
        prevText: '<i class="icon-arrow-left icons"></i>',
        nextText: '<i class="icon-arrow-right icons"></i>',
        before: function (slider: any) {
          $('.hero-caption').fadeOut().animate({ top: '-80px' }, { queue: false, easing: 'swing', duration: 700 });
          slider.slides.eq(slider.currentSlide).delay(500);
          slider.slides.eq(slider.animatingTo).delay(500);
        },
        after: function (slider: any) {
          $('.hero-caption').fadeIn().animate({ top: '0' }, { queue: false, easing: 'swing', duration: 700 });
        },
        useCSS: true  
      });

    });

  }

}
