		<!-- FEATURES -->
		<section class="module">
			<div class="container">

				<div class="row">
					<div class="col-sm-6 col-sm-offset-3">
						<div class="module-header text-center">
							<h2 class="montserrat text-uppercase">Our Services</h2>
							<p class="divider-line">Your Words, Our Expertise: Crafting Your Story, Publishing Your Success!</p>
						</div>
					</div>
				</div><!-- .row -->

				<div class="row">
					<div class="col-sm-10 col-sm-offset-1">
						<div class="text-center m-t-40 m-b-40">
							<img src="assets/images/banners/banner4.jpg" alt="">
						</div>
					</div>
				</div><!-- .row -->

				<div class="row multi-columns-row">

					<!-- ICONBOX -->
					<div class="col-sm-3 col-md-3 col-lg-3">
						<div class="box-icon text-center">
							<div class="icon-box-icon">
								<i class="icon-fire icons"></i>
							</div>
							<h4>Access to Artists and Works</h4>
							<p>Sign on as a member to access the works of our writers ready for collaboration.</p>
						</div>
					</div>
					<!-- END ICONBOX -->

					<!-- ICONBOX -->
					<div class="col-sm-3 col-md-3 col-lg-3">
						<div class="box-icon text-center">
							<div class="icon-box-icon">
								<i class="icon-wrench icons"></i>
							</div>
							<h4>A Venue for Collaboration</h4>
							<p>Choose from the creative options to see where and how you may collaborate.</p>
						</div>
					</div>
					<!-- END ICONBOX -->

					<!-- ICONBOX -->
					<div class="col-sm-3 col-md-3 col-lg-3">
						<div class="box-icon text-center">
							<div class="icon-box-icon">
								<i class="icon-earphones-alt icons"></i>
							</div>
							<h4>Opportunity for Marketeers</h4>
							<p>Register to become part of the team and use your expertise to market the literary works.</p>
						</div>
					</div>
					<!-- END ICONBOX -->

					<!-- ICONBOX -->
					<div class="col-sm-3 col-md-3 col-lg-3">
						<div class="box-icon text-center">
							<div class="icon-box-icon">
								<i class="icon-earphones-alt icons"></i>
							</div>
							<h4>Ability to Publish Online or In Print</h4>
							<p>Directly publish to an online digital platform or get it ready for print.</p>
						</div>
					</div>
					<!-- END ICONBOX -->					
				</div><!-- .row -->

			</div>
		</section>
		<!-- END FEATURES -->

