		<!-- PORTFOLIO -->
		<section class="module bg-white-dark p-b-0">
			<div class="container">

				<div class="row">
					<div class="col-sm-6 col-sm-offset-3">
						<div class="module-header text-center">
							<h2 class="montserrat text-uppercase">Books Portfolio</h2>
							<p class="lead divider-line">Where Words Meet Art: Crafting Stories, Creating Experiences</p>
						</div>
					</div>
				</div>

				<!-- PORTFOLIO FILTERS -->
				<div class="row">
					<div class="col-sm-12">
						<ul id="filters" class="filters">
							<li><a href="#" class="current" data-filter="*">All</a></li>
							<li><a data-filter=".fiction">Fiction</a></li>
							<li><a href="#" data-filter=".poems">Poetry</a></li>
							<li><a href="#" data-filter=".children">Children's Literature</a></li>
							<li><a href="#" data-filter=".plays">Plays</a></li>
						</ul>
					</div>
				</div>
				<!-- END PORTFOLIO FILTERS -->

				<!-- WORKS WRAPPER -->
				<div class="works-grid-wrapper">

					<!-- WORKS GRID -->
					<div id="works-grid" class="works-grid works-grid-4">

						<!-- PORTFOLIO ITEM -->
						<article class="work-item fiction">
							<div class="work-wrapper height-90">
								<img class="img-height-85" src="assets/images/books/breath.png" alt="">
								<div class="work-overlay">
									<div class="work-caption">
										<h6 class="work-title">Author: <a class="color-white" href="https://linkedln.com">Shoba Trivadi</a></h6>
										<span class="work-category">Fiction</span>
									</div>
								</div>
								<a href="portfolio-single-1.html" class="work-link"></a>
							</div>
						</article>
						<!-- END PORTFOLIO ITEM -->

						<!-- PORTFOLIO ITEM -->
						<article class="work-item fiction">
							<div class="work-wrapper height-90">
								<img class="img-height-85" src="assets/images/books/chaos.png" alt="">
								<div class="work-overlay">
									<div class="work-caption">
										<h6 class="work-title">Author: <a class="color-white" href="https://linkedln.com">Kalpana Trivadi</a></h6>
										<span class="work-category">Fiction</span>
									</div>
								</div>
								<a href="portfolio-single-1.html" class="work-link"></a>
							</div>
						</article>
						<!-- END PORTFOLIO ITEM -->

						<!-- PORTFOLIO ITEM -->
						<article class="work-item fiction">
							<div class="work-wrapper height-90">
								<img class="img-height-85" src="assets/images/books/childhood.png" alt="">
								<div class="work-overlay">
									<div class="work-caption">
										<h6 class="work-title">Author: <a class="color-white" href="https://linkedln.com">Shoba Trivadi</a></h6>
										<span class="work-category">Fiction</span>
									</div>
								</div>
								<a href="portfolio-single-1.html" class="work-link"></a>
							</div>
						</article>
						<!-- END PORTFOLIO ITEM -->

						<!-- PORTFOLIO ITEM -->
						<article class="work-item children">
							<div class="work-wrapper height-90">
								<img class="img-height-85" src="assets/images/books/hanuman.png" alt="">
								<div class="work-overlay">
									<div class="work-caption">
										<h6 class="work-title">Author: <a class="color-white" href="https://linkedln.com">Shoba Trivadi</a></h6>
										<span class="work-category">Children's Literature</span>
									</div>
								</div>
								<a href="portfolio-single-1.html" class="work-link"></a>
							</div>
						</article>
						<!-- END PORTFOLIO ITEM -->

						<!-- PORTFOLIO ITEM -->
						<article class="work-item plays">
							<div class="height-90">
								<div class="work-wrapper">
									<img class="img-height-85" src="assets/images/books/karma.png" alt="">
									<div class="work-overlay">
										<div class="work-caption">
											<h6 class="work-title">Author: <a class="color-white" href="https://linkedln.com">Shoba Trivadi</a></h6>
											<span class="work-category">Karma</span>
										</div>
									</div>
									<a href="portfolio-single-1.html" class="work-link"></a>
								</div>
							</div>
						</article>
						<!-- END PORTFOLIO ITEM -->

						<!-- PORTFOLIO ITEM -->
						<article class="work-item children">
							<div class="height-90">
								<div class="work-wrapper height-90">
									<img class="img-height-85" src="assets/images/books/mahabharath.jpg" alt="">
									<div class="work-overlay">
										<div class="work-caption">
											<h6 class="work-title">Author: <a class="color-white" href="https://linkedln.com">Shoba Trivadi</a></h6>
											<span class="work-category">Children's Literature</span>
										</div>
									</div>
									<a href="portfolio-single-1.html" class="work-link"></a>
								</div>
							</div>
						</article>
						<!-- END PORTFOLIO ITEM -->

						<!-- PORTFOLIO ITEM -->
						<article class="work-item children">
							<div class="height-90">
								<div class="work-wrapper height-90">
									<img class="img-height-85" src="assets/images/books/QuizzicalandWhimsical.png" alt="">
									<div class="work-overlay">
										<div class="work-caption">
											<h6 class="work-title">Author: <a class="color-white" href="https://linkedln.com">Shoba Trivadi</a></h6>
											<span class="work-category">Children's Literature</span>
										</div>
									</div>
									<a href="portfolio-single-1.html" class="work-link"></a>
								</div>
							</div>
						</article>
						<!-- END PORTFOLIO ITEM -->

						<!-- PORTFOLIO ITEM -->
						<article class="work-item poems">
							<div class="height-90">
								<div class="work-wrapper height-90">
									<img class="img-height-85" src="assets/images/books/poems.jpg" alt="">
									<div class="work-overlay">
										<div class="work-caption">
											<h6 class="work-title">Author: <a class="color-white" href="https://linkedln.com">Shoba Trivadi</a></h6>
											<span class="work-category">Poetry</span>
										</div>
									</div>
									<a href="portfolio-single-1.html" class="work-link"></a>
								</div>
							</div>
						</article>
						<!-- END PORTFOLIO ITEM -->

					</div>
					<!-- END WORKS GRID -->

				</div>
				<!-- END WORKS WRAPPER -->
			</div>				
		</section>
		<!-- END PORTFOLIO -->
