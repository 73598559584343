		<!-- ABOUT -->
		<section class="module">
			<div class="container">

				<div class="row">
					<div class="col-sm-6 col-sm-offset-3">
						<div class="module-header text-center">
							<h2 class="montserrat text-uppercase">Our story</h2>
							<p class="lead divider-line">We bring together a team of publishing experts who come together to create literary experiences.</p>
						</div>
					</div>
				</div><!-- .row -->

				<div class="row">
					<div class="col-sm-12">
						<img class="m-b-50" src="assets/images/banners/banner2.jpg" alt="">
					</div>
				</div><!-- .row -->

				<div class="row">
					<div class="col-sm-12">
						<p class="lead text-20 text-600">We invite authors, illustrators, designers, artists, composers, singers, musicians, movie makers, videographers, marketeers, and other talented publishing experts to work together to form literary works. Projects we engage in are novels, plays, stories, and other imaginary or real expressions allowing us to go from fact to fiction, from fantasy  to magical, from musical to dramatization, from poetries to rhyming couplets, and from children’s books to universal literature.</p>
						<br>
						<p class="lead text-20 text-600">Bringing together publishing experts to engage and craft literary works that captivate and inspire, we encourage artistic collaborations, that bring written words to life and create engaging experiences for our readers.</p>
					</div>
				</div><!-- .row -->

			</div>
		</section>
		<!-- END ABOUT -->
