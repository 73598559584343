		<!-- CALLOUT -->
		<section class="module bg-black-alfa-30 parallax color-white" data-background="assets/images/banners/banner3.jpg">
			<div class="container">

				<div class="row">
					<div class="col-sm-12">
						<div class="text-center">
							<h3 class="montserrat text-uppercase m-b-30 text-black">Unleash Your Story's Potential... <br> Collaborate, Create, Publish Together! <br> Register Today and Start Your Literary Journey!</h3>
							<a routerLink="/contact-us" routerLinkActive="active" class="btn btn-circle btn-lg btn-white">Register Today</a>
						</div>
					</div>
				</div><!-- .row -->

			</div>
		</section>
		<!-- END CALLOUT -->
		<!-- SERVICES -->
		<section class="module">
			<div class="container">

				<div class="row multi-columns-row">

					<!-- ICONBOX -->
					<div class="col-sm-4 col-md-4 col-lg-4">
						<div class="box-icon-left">
							<div class="icon-box-icon">
								<i class="icon-people icons"></i>
							</div>
							<h4>Artistic Partnerships </h4>
							<p>Where Creativity Flourishes, Stories Come Alive. Collaborate with us to craft unique literary experiences today!</p>
						</div>
					</div>
					<!-- END ICONBOX -->

					<!-- ICONBOX -->
					<div class="col-sm-4 col-md-4 col-lg-4">
						<div class="box-icon-left">
							<div class="icon-box-icon">
								<i class="icon-book-open icons"></i>
							</div>
							<h4>Creative Books</h4>
							<p>Where the essence of words meets the beauty of art, creating immersive experiences that resonate deeply with readers worldwide</p>
						</div>
					</div>
					<!-- END ICONBOX -->

					<!-- ICONBOX -->
					<div class="col-sm-4 col-md-4 col-lg-4">
						<div class="box-icon-left">
							<div class="icon-box-icon">
								<i class="icon-energy icons"></i>
							</div>
							<h4>Collaborative Creative Cuts</h4>
							<p>Where talents merge, stories thrive, and artistry breathes life into every page. Join us in crafting magic!</p>
						</div>
					</div>
					<!-- END ICONBOX -->

					<!-- ICONBOX -->
					<div class="col-sm-4 col-md-4 col-lg-4">
						<div class="box-icon-left">
							<div class="icon-box-icon">
								<i class="icon-printer icons"></i>
							</div>
							<h4>Digital and Printed Options</h4>
							<p>Offering versatility to bring your story to life in both traditional and modern formats. Explore your options</p>
						</div>
					</div>
					<!-- END ICONBOX -->

					<!-- ICONBOX -->
					<div class="col-sm-4 col-md-4 col-lg-4">
						<div class="box-icon-left">
							<div class="icon-box-icon">
								<i class="icon-user icons"></i>
							</div>
							<h4>Easy Access to Experts</h4>
							<p>Tap into our pool of publishing professionals for guidance and support in bringing your vision to fruition.</p>
						</div>
					</div>
					<!-- END ICONBOX -->

					<!-- ICONBOX -->
					<div class="col-sm-4 col-md-4 col-lg-4">
						<div class="box-icon-left">
							<div class="icon-box-icon">
								<i class="icon-camrecorder icons"></i>
							</div>
							<h4>Free Previews</h4>
							<p>Get a sneak peek into our literary world with complimentary trailers and previews. Dive in now!</p>
						</div>
					</div>
					<!-- END ICONBOX -->

				</div><!-- .row -->

			</div>
		</section>
		<!-- END SERVICES -->

