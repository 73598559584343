		<!-- HERO -->
		<div id="home" class="flexslider fullheight color-white">
			<ul class="slides">

				<!-- SLIDE 1 -->
				<li class="bg-black-alfa-40" style="background-image:url(assets/images/banners/banner1.jpg)">
					<!-- HERO TEXT -->
					<div class="hero-caption">
						<div class="hero-text">

							<div class="container">

								<div class="row">
									<div class="col-sm-12 text-center">
										<h1 class="montserrat text-xxxxl text-uppercase m-b-50 m-t-70">Welcome to<br>Xperia</h1>
										<p class="text-xl">Xperia welcomes collaborations for content creation & publishing literary works.</p>
										<div class="btn-list m-t-45">
											<a routerLink="/contact-us" routerLinkActive="active" class="btn btn-circle btn-white btn-lg">Register today</a>
										</div>
									</div>
								</div>

							</div>

						</div>
					</div>
					<!-- END HERO TEXT -->
				</li>
				<!-- END SLIDE 1 -->

				<!-- SLIDE 2  -->
				<li class="bg-black-alfa-40" style="background-image:url(assets/images/banners/banner1.jpg)">
					<!-- HERO TEXT -->
					<div class="hero-caption">
						<div class="hero-text">

							<div class="container">

								<div class="row">
									<div class="col-sm-12 text-center">
										<h1 class="montserrat text-xxxxl m-b-70">Create, Collaborate, and Customize publishing <br> with Xperia</h1>
										<p class="text-xl">Be part of our innovative and eclectic publishing team.</p>
									</div>
								</div>

							</div>

						</div>
					</div>
					<!-- END HERO TEXT -->
				</li>
				<!-- END SLIDE 2 -->

				<!-- SLIDE 3 -->
				<li class="bg-black-alfa-40" style="background-image:url(assets/images/banners/banner1.jpg)">
					<!-- HERO TEXT -->
					<div class="hero-caption">
						<div class="hero-text">

							<div class="container">

								<div class="row">
									<div class="col-sm-12 text-center">
										<h1 class="montserrat text-xxxxl m-b-70">Ability to Publish Online <br> or In Print</h1>
										<p class="text-xl">Directly publish to a online digital platform or allow your reader to send it on to be printed</p>
										<div class="btn-list m-t-45">
											<a routerLink="/contact-us" routerLinkActive="active" class="btn btn-circle btn-outline btn-white btn-lg">Register today</a>
										</div>
									</div>
								</div>

							</div>

						</div>
					</div>
					<!-- END HERO TEXT -->
				</li>
				<!-- END SLIDE 3 -->
			</ul>
		</div>
		<!-- END HERO -->

		<app-story></app-story>
		<app-services></app-services>
		<app-features></app-features>
		<app-team></app-team>

		<!-- BLOGS -->
		<section class="module bg-white-dark">
			<div class="container">

				<div class="row">
					<div class="col-sm-6 col-sm-offset-3">
						<div class="module-header text-center">
							<h2 class="montserrat text-uppercase">Our Videos</h2>
							<p class="lead divider-line">Read fresh news in our blog.</p>
						</div>
					</div>
				</div>

				<div class="row multi-columns-row post-columns">

					<!-- POST IMAGE -->
					<div class="col-sm-4 col-md-4 col-lg-4">
						<article class="post format-image bg-white">
							<div class="post-preview">
								<a href="#"><img src="assets/images/blog/2.jpg" alt=""></a>
							</div>
							<div class="post-content">
								<h2 class="post-title"><a href="#">The Trailers</a></h2>
								<ul class="post-meta">
									<li>March 24, 2024</li>
									<li>Learn <a href="#">more</a></li>
								</ul>
								<a href="https://youtu.be/LM-CgspQCwo">Karma, </a>
								<a href="https://youtu.be/wcOFlpbW-S4">Destiny, </a>
								<a href="https://youtu.be/25G7bCDd5pA">Mahabarata, </a>
								<a href="https://youtu.be/7rNHUsXtbn4">Nirvana, </a>
								<a href="https://youtu.be/Uuo3eD4nALs">Sthree, </a>
								<a href="https://youtu.be/LpZUn6kopxA">The Immigrant Song, </a>
								<a href="https://youtu.be/tbLeu23gXU4">Leafy And Friends, </a>
								<a href="https://youtu.be/DKtFjAFxsV8">Diana, and more!</a>
 <!--								<p>Destiny, Hanuman, Karma, Mahabarata, Nirvana, Sthree, The Immigrant Song, Dream, Silly Corny Story, OhhhMmmmm, Five Six Box, A Bunch of Quirky Tales, Vedic Math, Scandal, Tree House Trials, Cry, But Why Mommy, Mysteria, Leafy and Friends Trailer, Diana</p>-->
								<br><a href="#" class="btn btn-lg btn-link btn-base">Read more ›</a>
							</div>
						</article>
					</div>
					<!-- END POST IMAGE -->

					<!-- POST SLIDER -->
					<div class="col-sm-4 col-md-4 col-lg-4">
						<article class="post format-slider bg-white">
							<div class="post-preview">
								<div class="image-slider" data-carousel-options='{"transitionStyle": "fade"}'>
									<img src="assets/images/blog/1.jpg" alt="">
								</div>
							</div>
							<div class="post-content">
								<h2 class="post-title"><a href="#">Children's Literature</a></h2>
								<ul class="post-meta">
									<li>April 24, 2024</li>
									<li>Learn <a href="#">more</a></li>
								</ul>
								<a href="https://www.youtube.com/playlist?list=PLxxKSnHdRTfCAYKA8CbsXrwbhXFieViKC">Mr.Pigster, </a>
								<a href="https://youtu.be/9EN4_x38Zwc">Hanuman</a>
								<br><a href="#" class="btn btn-lg btn-link btn-base">Read more ›</a>
							</div>
						</article>
					</div>
					<!-- POST SLIDER -->

					<!-- POST VIDEO -->
					<div class="col-sm-4 col-md-4 col-lg-4">
						<article class="post format-video bg-white">
							<div class="post-preview">
								<iframe src="https://player.vimeo.com/video/144698619?byline=0" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
							</div>
							<div class="post-content">
								<h2 class="post-title"><a href="#">Poems</a></h2>
								<ul class="post-meta">
									<li>May 10, 2024</li>
									<li>Learn <a href="#">more</a></li>
								</ul>
								<p>Twist of Fate, Tsunami</p>
								<a href="#" class="btn btn-lg btn-link btn-base">Read more ›</a>
							</div>
						</article>
					</div>

					<div class="col-sm-4 col-md-4 col-lg-4">
						<article class="post format-video bg-white">
							<div class="post-preview">
								<iframe src="https://youtu.be/DChRoStnF0o" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
							</div>
							<div class="post-content">
								<h2 class="post-title"><a href="#">Interviews</a></h2>
								<ul class="post-meta">
									<li>May 10, 2024</li>
									<li>Learn <a href="#">more</a></li>
								</ul>
								<p>Sharad, XSEL Neal Rajesh</p>
								<a href="#" class="btn btn-lg btn-link btn-base">Read more ›</a>
							</div>
						</article>
					</div>

					<!-- END POST VIDEO -->

				</div><!-- .row -->

				<div class="row">
					<div class="col-sm-12">
						<div class="text-center m-t-35">
							<a href="#" class="btn btn-circle btn-lg btn-fade btn-base">Read all news</a>
						</div>
					</div>
				</div><!-- .row -->

			</div>
		</section>
    	<!-- END BLOGS -->

	