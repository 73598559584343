import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.css']
})
export class BooksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.formatStyle();
  }

  formatStyle() {
      var worksgrid = $('#works-grid'),
        worksgrid_mode = 'masonry';

      if (worksgrid.hasClass('works-grid-masonry')) {
        worksgrid_mode = 'masonry';
      } else {
        worksgrid_mode = 'fitRows';
      }


        worksgrid.isotope({
          layoutMode: worksgrid_mode,
          itemSelector: '.work-item',
        });

        var filters        = $('#filters'),
        worksgrid      = $('#works-grid'),
        worksgrid_mode = 'masonry';
  
      if (worksgrid.hasClass('works-grid-masonry')) {
        worksgrid_mode = 'masonry';
      } else {
        worksgrid_mode = 'fitRows';
      }
  
      $('a', filters).on('click', function() {
        var selector = $(event?.target).attr('data-filter');
        $('.current', filters).removeClass('current');
        $(event?.target).addClass('current');
        worksgrid.isotope({
          filter: selector
        });
        return false;
      }).scroll();
  
      $(window).on('resize', function() {
        worksgrid.imagesLoaded(function() {
          worksgrid.isotope({
            layoutMode: worksgrid_mode,
            itemSelector: '.work-item',
          });
        });
      }).resize();

  }
}
