		<!-- ABOUT -->
		<section class="module">
			<div class="container">

				<div class="row">
					<div class="col-sm-6 col-sm-offset-3">
						<div class="module-header text-center">
							<h2 class="montserrat text-uppercase">Register/Contact Us</h2>
							<p class="lead divider-line">Connect with Us - Let's bring your book to life!</p>
						</div>
					</div>
				</div><!-- .row -->

				<div class="row">
                    <div class="google-form-container">
                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdQqdaulX3PV50yRUfyEkHaH8sputMQ4U0wxloqGQK2qwBp7w/viewform?embedded=true" width="780" height="1250" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    </div>
				</div><!-- .row -->
			</div>
		</section>
		<!-- END ABOUT -->
