		<!-- TEAM -->
		<section class="module bg-white-dark">
			<div class="container">

				<div class="row">
					<div class="col-sm-6 col-sm-offset-3">
						<div class="module-header text-center">
							<h2 class="montserrat text-uppercase">Library</h2>
							<p class="lead divider-line">Our Library.</p>
						</div>
					</div>
				</div>

				<div class="row">

					<!-- TEAM MEMBER -->
					<div class="col-sm-6 col-md-3">
						<div class="team-item m-b-sm-35">
							<div class="team-photo">
								<img src="assets/images/team/team-1.jpg" alt="">
								<div class="team-social">
									<div>
										<div>
											<a href="#"><i class="fa fa-facebook"></i></a>
											<a href="#"><i class="fa fa-twitter"></i></a>
											<a href="#"><i class="fa fa-dribbble"></i></a>
										</div>
									</div>
								</div>
							</div>
							<div class="team-inner">
								<h6 class="team-name">Lyrical</h6>
								<span class="team-role">Logical</span>
							</div>
						</div>
					</div>
					<!-- END TEAM MEMBER -->

					<!-- TEAM MEMBER -->
					<div class="col-sm-6 col-md-3">
						<div class="team-item m-b-sm-35">
							<div class="team-photo">
								<img src="assets/images/team/team-2.jpg" alt="">
								<div class="team-social">
									<div>
										<div>
											<a href="#"><i class="fa fa-facebook"></i></a>
											<a href="#"><i class="fa fa-twitter"></i></a>
											<a href="#"><i class="fa fa-dribbble"></i></a>
										</div>
									</div>
								</div>
							</div>
							<div class="team-inner">
								<h6 class="team-name">Mythical</h6>
								<span class="team-role">Mystical</span>
							</div>
						</div>
					</div>
					<!-- END TEAM MEMBER -->

					<!-- TEAM MEMBER -->
					<div class="col-sm-6 col-md-3">
						<div class="team-item m-b-sm-35">
							<div class="team-photo">
								<img src="assets/images/team/team-3.jpg" alt="">
								<div class="team-social">
									<div>
										<div>
											<a href="#"><i class="fa fa-facebook"></i></a>
											<a href="#"><i class="fa fa-twitter"></i></a>
											<a href="#"><i class="fa fa-dribbble"></i></a>
										</div>
									</div>
								</div>
							</div>
							<div class="team-inner">
								<h6 class="team-name">Quizzical</h6>
								<span class="team-role">Whimsical</span>
							</div>
						</div>
					</div>
					<!-- END TEAM MEMBER -->

					<!-- TEAM MEMBER -->
					<div class="col-sm-6 col-md-3">
						<div class="team-item m-b-sm-35">
							<div class="team-photo">
								<img src="assets/images/team/team-4.jpg" alt="">
								<div class="team-social">
									<div>
										<div>
											<a href="#"><i class="fa fa-facebook"></i></a>
											<a href="#"><i class="fa fa-twitter"></i></a>
											<a href="#"><i class="fa fa-dribbble"></i></a>
										</div>
									</div>
								</div>
							</div>
							<div class="team-inner">
								<h6 class="team-name">Magical</h6>
								<span class="team-role">Musical</span>
							</div>
						</div>
					</div>
					<!-- END TEAM MEMBER -->

				</div><!-- .row -->

			</div>
		</section>
		<!-- END TEAM -->

