	    <!-- PRELOADER -->
        <div class="page-loader">
            <div class="loader">Loading...</div>
        </div>
        <!-- END PRELOADER -->
<!-- HEADER -->
<header class="header header-fixed header-transparent">
    <div class="container">
        <!-- YOUR LOGO HERE -->
        <div class="inner-header">
            <a class="inner-brand" routerLink="/home" routerLinkActive="active" >
                <img class="brand-light padding-10" src="assets/images/logo-light.png" width="120" alt="">
                <img class="brand-dark padding-10" src="assets/images/logo-dark.png" width="100" alt="">
            </a>
        </div>
    
        <!-- OPEN MOBILE MENU -->
        <div class="main-nav-toggle">
            <div class="nav-icon-toggle" data-toggle="collapse" data-target="#custom-collapse">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </div>
        </div>
    
        <!-- WIDGETS MENU -->
        <div class="inner-header pull-right">
            <div class="menu-extras clearfix">

                <div class="menu-item">
                    <div class="extras-cart">
                        <a href="#" id="open-cart">
                            <i class="icon-user btn btn-xs btn-primary icons"></i>
                            Register
                        </a>
                    </div>
                </div>

   
                <!-- SHOP CART 
                <div class="menu-item">
                    <div class="extras-cart">
                        <a href="#" id="open-cart">
                            <i class="icon-basket icons"></i>
                            <span class="cart-badge">2</span>
                        </a>
                    </div>
                </div>
                 END SHOP CART -->
    
                <!-- SEARCH 
                <div class="menu-item hidden-xxs">
                    <div class="extras-search">
                        <a id="modal-search" href="#"><i class="icon-magnifier icons"></i></a>
                        <form action="#" class="header-search-form">
                            <div class="search-form-inner">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="header-search-form-clouse">
                                                <a href="#" class="form-close-btn">✕</a>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" placeholder="Type &amp; hit enter">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                 END SEARCH -->
    
            </div>
        </div>
    
        <!-- MAIN MENU -->
        <nav id="custom-collapse" class="main-nav collapse clearfix">
            <ul class="inner-nav pull-right">
    
                <!-- HOME -->
                <li>
                    <a routerLink="/home" routerLinkActive="active" >Home</a>
                </li>
                <!-- END HOME -->
    
                <!-- PAGES -->
                <li class="has-submenu">
                    <a routerLink="/home" routerLinkActive="active" >About Us</a>
                    <ul class="submenu">
                        <li>
                            <a routerLink="/story" routerLinkActive="active" >Our Story</a>
                        </li>
                        <li>
                            <a routerLink="/team" routerLinkActive="active">Our Team</a>
                        </li>
                        <li>
                            <a routerLink="/contact-us" routerLinkActive="active">Contact Us</a>
                        </li>
                    </ul>
                </li>
                <!-- END PAGES -->
    
                <!-- FEATURES -->
                <li>
                    <a routerLink="/features" routerLinkActive="active">Features</a>
                </li>
                <!-- END FEATURES -->
    
                <!-- PORTFOLIO -->
                <li class="has-submenu">
                    <a routerLink="/books" routerLinkActive="active">Books</a>
                </li>
                <!-- END PORTFOLIO -->
    
                <!-- BLOG -->
                <li>
                    <a routerLink="/services" routerLinkActive="active">Services</a>						
                </li>
                <!-- END BLOG -->

                <!-- DIVIDER -->
                <li><a>&nbsp;</a></li>
            
            </ul>
        </nav>
    
    </div>
</header>
<!-- END HEADER -->

	<!-- WRAPPER -->
	<div class="wrapper">
        <router-outlet> </router-outlet>
    </div>


		<!-- FOOTER -->
		<footer class="footer">
			<div class="container">
			
				<div class="row">
			
					<!--  -->
					<div class="col-sm-3">
						<div class="widget">
							<p>We invite authors, artists, musicians, marketers, and other talented individuals to collaborate on novels, plays, and other literacy works</p>
			
							<ul class="social-icons social-icons-simple">
								<li><a href="#"><i class="fa fa-facebook"></i></a></li>
								<li><a href="#"><i class="fa fa-twitter"></i></a></li>
								<li><a href="#"><i class="fa fa-google-plus"></i></a></li>
							</ul>
						</div>
					</div>
					<!--  -->
			
		
					<!-- CONTACT WIDGET -->
					<div class="col-sm-3 pull-right">
						<div class="widget">
							<h6 class="montserrat text-uppercase bottom-line">Contact Us</h6>
							<address class="map-background">
								<p>Saratoga,<br>
								California, United States, 95070</p>
								Phone: 408 806-5499<br>
								Fax: 650 796-1479<br>
								E-mail: <a href="mailto:trivadi@trivadi.com">trivadi@trivadi.com</a>
							</address>
						</div>
					</div>
					<!-- END CONTACT WIDGET -->
			
				</div><!-- .row -->
			
				<div class="copyright">
			
					<div class="row">
			
						<div class="col-sm-6">
							<p class="m-0">
								Copyright <a href="#">Trivadi Inc.</a>, 2024, All Rights Reserved.
							</p>
						</div>
			
						<div class="col-sm-6">
							<div class="text-right">
								<a href="#top" class="scroll-top"><i class="fa fa-level-up"></i></a>
							</div>
						</div>
			
					</div><!-- .row -->
			
				</div>
			
			</div>
		</footer>
		<!-- END FOOTER -->

            <!-- SHOP CART -->
	<div class="off-canvas-cart">
	
		<div class="off-canvas-cart-wrapper">
	
			<div class="off-canvas-cart-header">
				<a id="cart-toggle" href="#">
					<i class="icon-arrow-right-circle icons"></i>
				</a>
			</div>
	
			<div class="off-canvas-cart-content">
				<div class="off-canvas-cart-content-wrap">
	
                    <div class="row">
                        <div class="col-sm-6 col-sm-offset-3">
                            <div class="module-header text-center">
                                <h2 class="montserrat text-uppercase">Register/Contact Us</h2>
                                <p class="lead divider-line">Connect with Us - Let's bring your book to life!</p>
                            </div>
                        </div>
                    </div><!-- .row -->
    
                    <div class="row">
                        <div class="google-form-container">
                            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdQqdaulX3PV50yRUfyEkHaH8sputMQ4U0wxloqGQK2qwBp7w/viewform?embedded=true" width="780" height="800" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                        </div>
                    </div><!-- .row -->
	
		</div>
	</div>
	<!-- END SHOP CART -->
